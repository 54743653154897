import React from "react";
import "./footer.css";
import Whitepaperone from "../documment/Mysacredfarm.pdf";

const Footer = () => {
  return (
    <div className="container-fluid w-100 footer-container ">
     
    </div>
  );
};
export default Footer;
